import React, { useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'

const VisitList = ({ date, storeName, visits }) => {
    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    const handleOpen = () => {
        setShow(true)
    }

    return (
        <>
            <Button className="primary-btn w-100" onClick={handleOpen}>
                {new Date(date).toLocaleString('el-GR', { day: 'numeric', month: 'short', year: 'numeric' })}
            </Button>

            <Modal show={show} onHide={handleClose} centered size='xl'>
                <Modal.Header closeButton>
                    <Modal.Title className="card-header">Ιστορικό Επικοινωνίας για το Κατάστημα: {storeName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped hover responsive>
                        <thead>
                            <tr>
                                <th>Τύπος Επικοινωνίας</th>
                                <th>Ημερομηνία</th>
                                <th>Πωλητής</th>
                                <th>Σχόλια</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visits.map((visit) => (
                                <tr key={visit._id}>
                                    <td>{visit.visitType}</td>
                                    <td>{new Date(visit.visitDate).toLocaleString('el-GR', { day: 'numeric', month: 'long', year: 'numeric' })}</td>
                                    <td>{visit.userID?.username}</td>
                                    <td>{visit.comments}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </Modal.Body>

            </Modal >
        </>
    )
}

export default VisitList